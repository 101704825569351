import Vue from 'vue'
import Router from 'vue-router'

import Crece from '../pages/Crece'
import Equipo from '../pages/Equipo'
import Florece from '../pages/Florece'
import Home from '../pages/Home'
import Medellin from '../pages/Medellin'
import Semilla from '../pages/Semilla'
import TierraFertil from '../pages/TierraFertil'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: 'home',
            component: Medellin,
            children: [
                { path: 'home', component: Home, name: 'home' },
                { path: 'crece', component: Crece, name: 'crece' },
                { path: 'equipo', component: Equipo, name: 'equipo' },
                { path: 'florece', component: Florece, name: 'florece' },
                { path: 'semilla', component: Semilla, name: 'semilla' },
                { path: 'tierra-fertil', component: TierraFertil, name: 'tierra-fertil' }
            ]
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router