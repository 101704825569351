<template>
  <div>
    <Menu/>
    <transition mode="out-in"
                name="fade">
      <router-view style="animation-duration: 0.3s"/>
    </transition>
  </div>
</template>

<script>
import Menu from '../components/Menu';

export default {
  components: { Menu }
}
</script>