<template>
  <div>
    <div class="container-crece">
      <div class="row wrap">
        <div class="col-12 pt-9">
          <h2 class="main-title">
            Manual <br> de marca
          </h2>
        </div>

        <div class="col-12">
          <transition name="fade">
            <div class="video-container"
                 style="animation-delay: .5s"
                 v-if="startTransition">
              <iframe width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/CzLsukR6hek"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
              </iframe>
            </div>
          </transition>
        </div>

        <div class="col-12 pt-5">
          <h2>Un logo <br> panorámico <br> con personalidad</h2>
        </div>

        <div class="col-12 pr-design">
          <p>Ver la palabra Medellín escrita tiene un atractivo natural; un aura que llama la atención naturalmente. Por eso decidimos que la cara de la marca fueran las mismas letras de su nombre. La tipografía serif que elegimos es contemporánea y alegre, fresca, como lo que hoy en día se respira y brota por toda la ciudad. Cada letra está inspirada en nuestro paisaje y esencia, en lo que uno ve y siente caminando por las lomas, calles y barrios de la ciudad.</p>
        </div>

        <div class="col-12 align-center">
          <img src="../assets/fuente-nombre.png" alt="logo" style="max-width: 760px">
        </div>

        <div class="col-12 align-center mb-0">
          <div class="slider">
            <div class="slider-container">
              <div class="slide">
                <h3>1.</h3>
                <br>
                Las letras "M" y la "N" abrazan el logotipo formando las montañas que nos rodean
              </div>

              <div class="slide">
                <h3>2.</h3>
                <br>
                Letras "E" alegres y sonrientes
              </div>
              <div class="slide">
                <h3>3.</h3>
                <br>
                Ángulos en crecimiento que representan nuestra arquitectura y espíritu emprendedor
              </div>
            </div>

            <div class="slider-container">
              <div class="slide">
                <h3>4.</h3>
                <br>
                La tilde es un pétalo, dibujado de una manera inesperada. Como nosotros mismos, impredecibles, pero siempre alertas, alegres, dispuestos
              </div>

              <div class="slide">
                <h3>5.</h3>
                <br>
                Piso sólido, la tierra fértil en la que todo florece
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-5">
          <img src="../assets/logo-espanol.png" alt="logo">
        </div>

        <div class="col-12">
          <img src="../assets/logo-ingles.png" alt="logo">
        </div>

        <div class="title-left-text-right mt-9">
          <div class="title">
            <h2>Un símbolo atemporal</h2>
          </div>

          <div class="text">
            <p>La tilde es el pétalo de una flor que a su vez es un elemento gráfico. Sus siete pétalos representan nuestros siete <br> cerros tutelares.</p>
            <p>Se utiliza como símbolo gráfico, y también como ventana que refleja y da luz a todo lo que florece en la ciudad. La flor no es "el logotipo", es un ícono que acompaña la marca.</p>
          </div>
        </div>

        <div class="col-sm-6">
          <MedellinGris/>
        </div>

        <div class="col-sm-6 justify-center">
          <img src="../assets/estructura-petalo.png"
               alt="Estructura petalo"
               style="max-width: 413px">
        </div>

        <div class="col-12 mt--10 mb-10">
          <div class="row justify-space-between">
            <div style="width: 30%">
              <FlorRojaContorno/>
            </div>

            <div style="width: 30%">
              <FlorRoja/>
            </div>

            <div style="width: 30%">
              <FlorRojaRellena/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-crece full-width">
      <div class="row justify-space-between">
        <div style="width: 30%">
          <FlorAmarillaBackgroundRojo/>
        </div>

        <div style="width: 30%">
          <FlorAzulBackgroundVerde/>
        </div>

        <div style="width: 30%">
          <FlorVerdeBackgroundAmarillo/>
        </div>
      </div>
    </div>

    <div class="container-crece">
      <div class="row wrap">
        <div class="col-12 mt-9">
          <h2>Un logo que <br> se adapta a <br> su contexto</h2>
        </div>

        <div class="col-12 col-sm-6 justify-center">
          <p>Además del uso principal del logotipo —sencillo y sin adornos— también se puede usar una composición que incluye la flor y la idea de marca. Esta variante solo se utiliza en piezas que no contengan ningún otro mensaje o elemento gráfico.</p>
          <p>La versión reducida del logo —las letras "MDE", nuestro código IATA— se utiliza en espacios reducidos, sobre todo en aplicaciones digitales. Puede utilizarse como un sello reconocible para la ciudad.</p>
        </div>

        <div class="col-12 pb-9">
          <div class="slider">
            <div class="slider-container">
              <img src="../assets/Logo-1.png" alt="Logo 1">
            </div>

            <div class="slider-container">
              <img src="../assets/Logo-2.png" alt="Logo 2">
            </div>

            <div class="slider-container">
              <img src="../assets/Logo-3.png" alt="Logo 3">
            </div>

            <div class="slider-container">
              <img src="../assets/Logo-4.png" alt="Logo 4">
            </div>
          </div>
        </div>

        <div class="title-left-text-right">
          <div class="title">
            <h2>¿De qué color es Medellín?</h2>
          </div>

          <div class="text pb-7" style="padding-left: 5rem">
            <p>Las nubes de naturaleza asomándose entre bloques de edificios le dan el color característico al Valle de Aburrá. La tonalidad particular de nuestros ladrillos no es gratuita: son así porque provienen de la tierra bajo nuestros pies, esa tierra fértil donde todo florece. Y el verde que vemos a nuestro alrededor no es cualquier verde, es el tono oscuro de bosques, algunos nativos, otros traídos, que toma matices azules en la distancia.</p>
            <p>Esta experiencia visual se resume en el guayaba y el verde montaña, la gama principal de la marca. Para darle un toque de diversidad tonal utilizamos verde guarapo, azul cielo, y guayacán como colores de acento, y mantequilla como tono neutro que reemplaza el blanco.</p>
            <p>Fresca, contrastada y moderna, esta paleta refleja nuestra esencia colorida, y proyecta la ciudad hacia <br> el futuro.</p>
            <p>Los colores principales —guayaba y verde montaña— siempre deben ser protagonistas. El logotipo puede utilizarse en verde montaña sobre cualquier color de la paleta, o en guayaba sobre cualquiera, menos azul cielo. Cuando se incluye la flor, esta puede ser del mismo color del logotipo, o de cualquier otro tono <br> de la marca.</p>
          </div>
        </div>

        <div class="col-12">
          <Colores/>
        </div>
      </div>
    </div>

    <div class="container-crece full-width">
      <div class="row justify-space-between">
        <div>
          <FlorAmarillaCircular/>
        </div>

        <div>
          <FlorAzulCircular/>
        </div>

        <div>
          <FlorRojaCircular/>
        </div>

        <div>
          <FlorVerdeCircular/>
        </div>
      </div>
    </div>

    <div class="container-crece">
      <div class="row wrap">
        <div class="title-left-text-right">
          <div class="title">
            <h2>Una tipografía moderna, alegre y legible</h2>
          </div>

          <div class="text pb-7" style="padding-left: 5rem">
            <p>Utilizamos una única familia tipográfica: Isidora Sans, de la fundición argentina Latinotype. El contraste entre su estructura geométrica y sus formas amables le dan un aire fresco y contemporáneo a los textos.</p>
            <h3 class="semilla-color mt-4">Isidora Sans bold</h3>
            <h3 class="semilla-color">(titulares)</h3>

            <div class="crece-divider"></div>

            <p class="mb-0 semilla-color font-bold">ABCDEFGHIJKLMNÑOPQRSTUVWXYZ</p>
            <p class="mb-0 semilla-color font-bold">abcdefghijklmnñopqrstuvwxyz</p>
            <p class="mb-0 semilla-color font-bold">!(%)[#]{@}/&amp;\&lt;+=-&divide;&gt;&copy;$%&cent;&pound;&#8260;&yen;:;,.</p>
            <p class="mb-0 semilla-color font-bold">1234567890</p>

            <img class="my-3"
                 src="../assets/flor-pequena.png"
                 alt="Flor pequena"
                 style="max-width: 60px">

            <h3 class="semilla-color font-normal">Isidora Sans regular</h3>
            <h3 class="semilla-color font-normal">(cuerpos de texto)</h3>

            <div class="crece-divider"></div>

            <p class="mb-0 semilla-color">ABCDEFGHIJKLMNÑOPQRSTUVWXYZ</p>
            <p class="mb-0 semilla-color">abcdefghijklmnñopqrstuvwxyz</p>
            <p class="mb-0 semilla-color">!(%)[#]{@}/&amp;\&lt;+=-&divide;&gt;&copy;$%&cent;&pound;&#8260;&yen;:;,.</p>
            <p class="mb-0 semilla-color">1234567890</p>
          </div>
        </div>

        <div class="col-12">
          <Bienvenida/>
        </div>

        <div class="col-12 pt-5">
          <h2>Los íconos que <br> nos representan</h2>
        </div>

        <div class="col-12 pr-design">
          <p>La iconografía —representaciones esquemáticas de símbolos de la ciudad— es un recurso útil y versátil que enriquece el sistema visual de la marca. Partimos de la geometría del pétalo para construir un universo gráfico que se puede utilizar tanto para darle aún más vida a piezas creativas, como para hacer comunicación funcional, incluyendo mapas <br> y señalética.</p>
        </div>
      </div>
    </div>

    <div class="container-crece full-width pt-5 pb-9">
      <div class="col-12" style="padding: 0 15rem">
        <Iconos/>
      </div>
    </div>

    <div class="container-crece pb-5">
      <div class="row wrap">
        <div class="title-left-text-right">
          <div class="title">
            <h2>Paisajes, detalles y retratos que despiertan arraigo</h2>
          </div>

          <div class="text justify-start" style="padding-left: 5rem">
            <p>Nuestra fotografía transmite lo que se siente vivir la ciudad. Combinamos planos abiertos con detalles inesperados que dan sensación de lugar, de quien tiene raíces y sabe lo que se respira y retoña <br> en Medellín.</p>
            <p>Los retratos son naturales, mostrando interacciones y emociones espontáneas bajo luz ambiente en entornos reales.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-crece full-width pt-5 pb-5">
      <div class="row justify-space-around">
        <div style="max-width: 24%">
          <img src="../assets/mangos.jpg" alt="Poster 1">
        </div>

        <div style="max-width: 24%">
          <img src="../assets/cielo.jpg" alt="Poster 2">
        </div>

        <div style="max-width: 24%">
          <img src="../assets/comuna2.jpg" alt="Poster 3">
        </div>

        <div style="max-width: 24%">
          <img src="../assets/patinador.jpg" alt="Poster 4">
        </div>
      </div>
    </div>


    <div class="container-crece pt-5 pb-5">
      <div class="col-12 pb-5">
        <div class="title-left-text-right">
          <div class="title">
            <h2>Belleza funcional</h2>
          </div>

          <div class="text"
               style="padding-left: 5rem">
            <p>Aprovechamos la geometría del pétalo para crear una cuadrícula que permite organizar la información en franjas que facilitan la lectura y establecen bloques <br> de color. </p>
          </div>
        </div>
      </div>

      <div class="col-12">
        <Contornos/>
      </div>

      <div class="col-12 mt-6 justify-center align-center font-color-semilla"
           @click="$router.push('/florece')">
        <div class="button-next-page">
          Siguiente
          <img class="ml-1"
               src="../assets/arrow_forward_guayaba.svg"
               alt="Siguiente">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bienvenida from '../animations/Bienvenida'
import Colores from '../animations/Colores'
import Contornos from '../animations/Contornos'
import FlorAmarillaCircular from '../animations/Flores/FlorAmarillaCircular'
import FlorAzulCircular from '../animations/Flores/FlorAzulCircular'
import FlorRojaCircular from '../animations/Flores/FlorRojaCircular'
import FlorVerdeCircular from '../animations/Flores/FlorVerdeCircular'
import FlorAmarillaBackgroundRojo from '../animations/FlorAmarillaBackgroundRojo'
import FlorAzulBackgroundVerde from '../animations/FlorAzulBackgroundVerde'
import FlorRoja from '../animations/FlorRoja'
import FlorRojaContorno from '../animations/FlorRojaContorno'
import FlorRojaRellena from '../animations/FlorRojaRellena'
import FlorVerdeBackgroundAmarillo from '../animations/FlorVerdeBackgroundAmarillo'
import Iconos from '../animations/Iconos'
import MedellinGris from '../animations/MedellinGris'

export default {
  components: {
    Bienvenida,
    Colores,
    Contornos,
    FlorAmarillaCircular,
    FlorAzulCircular,
    FlorRojaCircular,
    FlorVerdeCircular,
    FlorAmarillaBackgroundRojo,
    FlorAzulBackgroundVerde,
    FlorRoja,
    FlorRojaContorno,
    FlorRojaRellena,
    FlorVerdeBackgroundAmarillo,
    Iconos,
    MedellinGris
  },
  data: () => ({
    startTransition: false
  }),
  mounted() {
    const $ = window.$
    this.startTransition = true

    $(document).ready(function(){
      $('.slider').bxSlider({
        hideControlOnEnd: true,
        infiniteLoop: false,
        minSlides: 1,
        maxSlides: 1
      });
    });
  },
  methods: {
    callbackFunc (entries) {
      entries.forEach(entry => {
        const txt = entry.target.id + " visibility: " + entry.isIntersecting;

        document.getElementById('log').appendChild(document.createTextNode(txt));
        document.getElementById('log').appendChild(document.createElement("br"));
      });
    }
  }
}
</script>