<template>
  <div class="main-menu-container">
    <div class="sticky-menu" :class="[scrollY > 409 ? 'active' : '', menuItems[selectedPage].class]">
      <span @click="goto(item)"
            :key="'sticky-' + item.class"
            v-for="(item, index) in menuItems"
            :class="[selectedPage !== 1 ? 'font-color-montana' : 'font-color-guarapo', selectedPage === index ? 'font-weight-500' : '']">
        {{item.title}}
      </span>
    </div>

    <div :class="[item.class, selectedPage === item.number ? 'selected' : '']"
         class="menu-item-container"
         @click="goto(item, index)"
         :key="item.class"
         v-for="(item, index) in menuItems">
      <div class="menu-item" :class="selectedPage === item.number ? 'selected' : ''">
        <div class="info-container">
          <div :class="item.class !== 'tierra-fertil' ? 'font-color-montana' : ''">
            {{ item.title }}
          </div>

          <div class="menu-item-subtitle"
               :class="item.class !== 'tierra-fertil' ? 'font-color-montana' : ''"
               v-if="item.subtitle">
            {{ item.subtitle }}
          </div>
        </div>

        <div class="flex align-center"
             :class="item.class !== 'tierra-fertil' ? 'font-color-montana' : ''">
          {{ item.number > 0 ? item.number : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    this.selectedPage = this.menuItems.find(item => item.class === this.$route.name).number
    window.addEventListener('scroll', () => {
      this.scrollY = window.scrollY;
    })
  },
  data: () => ({
    menuItems: [
      { title: 'Home', subtitle: '', class: 'home', number: 0 },
      { title: 'Tierra fértil', subtitle: '(Marco teórico)', class: 'tierra-fertil', number: 1 },
      { title: 'Semilla', subtitle: '(Hallazgos)', class: 'semilla', number: 2 },
      { title: 'Crece', subtitle: '(Manual de marca)', class: 'crece', number: 3 },
      { title: 'Florece', subtitle: '(Aplicaciones)', class: 'florece', number: 4 },
      { title: 'Equipo', subtitle: '', class: 'equipo', number: 5 }
    ],
    scrollY: 0,
    selectedPage: 0
  }),
  methods: {
    goto (item) {
      this.selectedPage = item.number
      this.$router.push(item.class)
    }
  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  watch: {
    '$route.name' (value) {
      this.selectedPage = this.menuItems.find(item => item.class === value).number
    }
  }
}
</script>

<style>
.main-menu-container {
  z-index: 3;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-top: -6rem;
  z-index: 3;
}

.logo-container img {
  z-index: 3;
  cursor: pointer;
}

.logo-container img {
  width: 80%;
  max-width: 512px;
}

.menu-item {
  display: flex;
  font-weight: bold;
  height: 3.8rem;
  padding: 1rem 2rem;
  width: 100%;
  transition: all ease-in-out 0.1s;
}

.menu-item:hover, .menu-item.selected {
  height: 7.5rem;
}

.menu-item-container {
  cursor: pointer;
  display: flex;
}

.home {
  background-color: var(--home);
  color: var(--homeFuente);
}

.tierra-fertil {
  background-color: var(--tierraFertil);
  color: var(--tierraFertilFuente);
}

.semilla {
  background-color: var(--semilla);
  color: var(--semillaFuente);
}

.crece {
  background-color: var(--crece);
  color: var(--creceFuente);
}

.florece {
  background-color: var(--florece);
  color: var(--floreceFuente);
}

.equipo {
  background-color: var(--equipo);
  color: var(--equipoFuente);
}

.menu-item-subtitle {
  opacity: 0;
  height: 0;
  font-weight: normal;
  font-size: 1.5rem;
  transition: all .1s ease-in-out;
}

.menu-item.selected .menu-item-subtitle {
  height: 1.7rem;
  opacity: 1;
}

.menu-item-container:hover .menu-item-subtitle {
  height: 1.7rem;
  opacity: 1;
}

.info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.sticky-menu {
  padding: 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  margin-top: -6rem;
  transition: all .2s ease-in-out;
}

.sticky-menu.active {
  margin-top: 0;
}

.sticky-menu span {
  cursor: pointer;
  font-weight: bold;
  padding: 0 2rem;
}

.sticky-menu span:first-child {
  padding-left: 0;
}

.font-weight-500 {
  cursor: default !important;
  font-weight: 500 !important;
}
</style>