<template>
  <div>
    <div class="container-florece full-width">
      <div class="row wrap">
        <div class="col-12 mb-0">
          <img src="../assets/letras_3d.jpg" alt="Letras 3d">
        </div>
      </div>
    </div>

    <div class="full-width background-crece">
      <div class="container-florece">
        <div class="row wrap">
          <div class="col-12 pt-9">
            <h2 class="main-title">
              Aplicaciones de identidad visual
            </h2>
          </div>

          <div class="col-12 col-sm-6 mt-6">
            <p>Una marca es más que un nombre y un logotipo, es un imaginario compartido que se cultiva en cada contacto con las personas y la cultura. Es afuera, en la calle, las pantallas y la vida, que la marca abre sus alas y cobra sentido. Esta es la expresión externa de la marca Medellín que soñamos.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width background-crece pt-3 pb-7">
      <div class="container-florece florece-slider-container">
        <div class="slider">
          <div class="slider-container">
            <img src="../assets/mural_2.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/mural_1.jpg" alt="Mural 1">
          </div>
        </div>
      </div>
    </div>

    <div class="container-florece full-width">
      <div class="row wrap">
        <div class="col-12 mb-0">
          <img src="../assets/metro.jpg" alt="Letras 3d">
        </div>
      </div>
    </div>

    <div class="full-width background-crece pt-5 pb-7">
      <div class="container-florece">
        <div class="row wrap">
          <div class="col-12 col-sm-6">
            <h3>Intervenciones de ciudad, metro y muralismo</h3>
          </div>

          <div class="col-12 pr-design">
            <p>La publicidad exterior es la manera más rápida e impactante de lanzar una marca en el mundo real. Si bien los medios digitales son personalizados y no tienen barreras, una valla es más poderosa pues es un acto público: para una persona de a pié, no es solo leer una pieza de comunicación, es darse cuenta de que todos los demás también la ven. Así se hacen famosas las marcas.</p>
          </div>
        </div>
      </div>

      <div class="container-florece full-width florece-slider-container">
        <div class="slider">
          <div class="slider-container">
            <img src="../assets/ciclismo.jpg" alt="Totem">
          </div>

          <div class="slider-container">
            <img src="../assets/totem.jpg" alt="Totem">
          </div>

          <div class="slider-container">
            <img src="../assets/tienda_de_barrio.jpg" alt="Tienda de barrio">
          </div>
        </div>
      </div>
    </div>



    <div class="container-florece full-width">
      <div class="row wrap">
        <div class="col-12">
          <video autoplay
                 loop
                 src="../animations/time_square.mp4">
          </video>
        </div>
      </div>
    </div>

    <div class="container-florece">
      <div class="row wrap">
        <div class="col-12 mt-9">
          <h3>Presencia de marca <br> en otros países</h3>
        </div>

        <div class="col-12 pr-design">
          <p>Para enamorar al público extranjero, aprovechamos <br> la versión anglosajona de nuestra idea de marca <br> —<span class="font-italic">Where everything flowers</span>— como plataforma <br> de comunicación.</p>

          <p>Los textos resaltan siempre esos detalles que sorprenden y diferencian a Medellín de cualquier otra ciudad del mundo.</p>
        </div>
      </div>
    </div>

    <div class="container-florece full-width florece-slider-container pb-7">
      <div class="slider">
        <div class="slider-container">
          <video style="max-width: 1308px;"
                 src="../animations/avion.mp4"
                 autoplay loop>
          </video>
        </div>

        <div class="slider-container">
          <img src="../assets/taxi.jpg" alt="Mural 2">
        </div>

        <div class="slider-container">
          <img src="../assets/subway.jpg" alt="Mural 1">
        </div>

        <div class="slider-container">
          <img src="../assets/aeropuerto.jpg" alt="Mural 2">
        </div>
      </div>
    </div>

    <div class="full-width background-home pt-9 pb-9">
      <div class="container-florece full-width">
        <div class="row wrap">
          <div class="col-12 mb-0">
            <img src="../assets/revista.jpg" alt="Revista">
          </div>
        </div>
      </div>
    </div>

    <div class="full-width background-tierra-fertil pt-5 pb-7">
      <div class="container-florece full-width">
        <div class="row wrap">
          <div class="col-12">
            <img src="../assets/post.jpg" alt="Revista">
          </div>
        </div>
      </div>

      <div class="container-florece">
        <div class="row wrap">
          <div class="col-12">
            <h3>Medios digitales</h3>
          </div>

          <div class="col-12 pr-design">
            <p>En comunicación digital utilizamos un lenguaje visual limpio y fresco, con textos cortos y llamativos que expresan la propuesta de valor de la ciudad para públicos extranjeros.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width background-semilla pt-5 pb-7">
      <div class="container-florece full-width">
        <div class="row wrap">
          <div class="col-12">
            <img src="../assets/postal.jpg" alt="Revista">
          </div>
        </div>
      </div>

      <div class="container-florece">
        <div class="row wrap">
          <div class="col-12">
            <h3 class="font-color-mantequilla">Objetos y accesorios</h3>
          </div>

          <div class="col-12 pr-design">
            <p class="font-color-mantequilla">La marca va más allá de la comunicación. También abarca la moda y lo que llevamos con nosotros para recordar una experiencia, por eso en materiales promocionales prima la belleza sobre la función.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width background-crece pt-9 pb-5">
      <div class="container-florece florece-slider-container pb-9">
        <div class="slider">
          <div class="slider-container">
            <img src="../assets/lapiceros.jpg" alt="Mural 1">
          </div>

          <div class="slider-container">
            <img src="../assets/llaveros.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/mapa.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/panoletas.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/sombrillas.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/postal2.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/stickers.png" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/tienda-medellin.jpg" alt="Mural 2">
          </div>

          <div class="slider-container">
            <img src="../assets/tote-bags.jpg" alt="Mural 2">
          </div>
        </div>
      </div>

      <div class="col-12 mt-6 justify-center align-center font-color-montana"
           @click="$router.push('/equipo')">
        <div class="button-next-page">
          Siguiente
          <img class="ml-1"
               src="../assets/arrow_forward_montana.svg"
               alt="Siguiente">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    const $ = window.$

    $(document).ready(function(){
      $('.slider').bxSlider({
        hideControlOnEnd: true,
        infiniteLoop: false,
        minSlides: 1,
        maxSlides: 1
      });
    });
  }
}
</script>