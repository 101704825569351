<template>
  <div>
    <transition name="fade">
      <div class="video-home-container"
           v-if="startTransition">
        <iframe class="youtube-home-video"
                src="https://www.youtube.com/embed/icDXAbB3ncU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
        </iframe>
      </div>
    </transition>

    <div class="container-home">
      <div class="row wrap">
        <div class="col-12 my-6"
             v-if="startTransition"
             style="animation-delay: 1s">


          <div class="title-left-text-right">
            <div class="title flex align-center">
              <h2 class="font-color-semilla">Una marca para Medellín que hace florecer <br> la ciudad</h2>
            </div>

            <div class="text" style="padding-left: 5rem">
              <p>Esta es una propuesta que refleja la esencia histórica de Medellín como tierra fértil para la innovación cultural, tecnológica y empresarial.</p>
              <p>La imagen de la ciudad en el mundo se ha transformado, y la marca refleja esta evolución.</p>
              <p>Partimos de un nuevo posicionamiento de ciudad <br> —<span class="font-italic">Aquí todo florece</span>— para desarrollar una identidad visual fresca que proyecta la esencia de Medellín hacia el futuro.</p>
              <p>Para lograrlo, diseñamos un logotipo alegre y dinámico que representa visualmente el paisaje de la ciudad y un sistema de diseño que evoca nuestro espíritu pionero.</p>
              <p>Desde viajeros e inversionistas, hasta residentes e inmigrantes, esta marca fue pensada para hacer que las personas quieran vivir a Medellín.</p>
            </div>
          </div>
        </div>

        <div class="col-12 my-6 justify-center align-center font-color-semilla"
             @click="$router.push('/tierra-fertil')">
          <div class="button-next-page">
            Siguiente
            <img class="ml-1"
                 src="../assets/arrow_forward_guayaba.svg"
                 alt="Siguiente">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    buttonPressed: 0,
    enter: 'fadeInLeft',
    leave: 'fadeInRight',
    selectedStep: 0,
    startTransition: false
  }),
  methods: {
    lastStep () {
      if (this.selectedStep === 0) return
      this.buttonPressed = 0;
      this.selectedStep--;
    },
    nextStep () {
      if (this.selectedStep === 4) return
      this.buttonPressed = 1;
      this.selectedStep++;
    }
  },
  mounted () {
    this.startTransition = true
  }
}
</script>