<template>
  <div>
    <div class="full-width background-home pt-9 pb-9">
      <div class="container-semilla">
        <div class="col-12 pb-9">
          <h2 class="main-title">
            Hallazgos <br> estratégicos
          </h2>
        </div>

        <div class="col-12">
          <div class="title-left-text-right mt-4">
            <div class="title">
              <h3>Público objetivo</h3>
              <h2>Exploradores culturales</h2>
            </div>

            <div class="text pl-3">
              <p>Mujeres, hombres y jóvenes que viven o quieren vivir lugares de una manera profunda. Personas que conocen el valor de una ciudad y se arriesgan a vivirla por completo. Viajeros que prefieren ir más allá de las atracciones turísticas típicas.</p>
              <p>Son aquellos que quieren ser parte de algo más grande, los creativos y los curiosos, quienes se transforman cada día, quienes buscan sentirse parte del lugar en el <br> que están.</p>
              <p>También son todos los que viven, sienten <br> y respiran Medellín.</p>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="title-left-text-right mt-4">
            <div class="title">
              <h3>Propuesta de valor</h3>
              <h2>Medellín es tierra fértil</h2>
            </div>

            <div class="text pl-3">
              <p><strong>... para la biodiversidad y la naturaleza</strong></p>
              <p><strong>... para el emprendimiento y la inversión</strong></p>
              <p><strong>... para la producción e innovación cultural</strong></p>
              <p><strong>... para el entretenimiento, la aventura y la diversión</strong></p>
              <p><strong>... para la vida en la ciudad</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-semilla pt-9 pb-9">
      <h2 class="semilla-color">Círculo <br> Dorado</h2>

      <h4 class="semilla-color mt-0 pb-3">
        Metodología del <br> <span class="font-italic">Golden Circle</span>
      </h4>

      <div class="row wrap">
        <div class="flex align-center pr-2" style="width: 50%">
          <CirculoDorado/>
        </div>

        <div style="width: 50%;">
          <transition name="fadeRight">
            <h3 v-if="startTransition"
                class="font-normal semilla-color pb-2"
                style="animation-delay: 1.5s">
              <strong>¿Para qué? <h5 class="display-inline">(Propósito superior)</h5></strong>
            </h3>
          </transition>

          <transition name="fadeUp">
            <div class="pb-3"
                 v-if="startTransition"
                 style="animation-delay: 2s">
              <p>
                Ser tierra fértil para la innovación cultural, tecnológica y empresarial; en Colombia y el mundo.
              </p>

              <h3 class="font-normal semilla-color pb-3 pt-3" style="line-height: 0.8">
                <strong>¿Cómo? <h5 class="display-inline">(Guías de comportamiento para materializar el propósito)</h5></strong>
              </h3>

              <p>Adaptándonos al cambio</p>
              <p>Reverdeciendo nuestro entorno</p>
              <p>Creando nuevas ideas y soluciones</p>
              <p>Alegrando a las personas</p>


              <h3 class="font-normal semilla-color pb-3 pt-1">
                <strong>¿Qué? <h5 class="display-inline">(Oferta comercial)</h5></strong>
              </h3>

              <p>Medellín como ciudad para visitar</p>
              <p>Medellín como ciudad para vivir</p>
              <p>Medellín como ciudad para invertir</p>
              <p>Medellín como ciudad para inspirar</p>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <div class="full-width background-crece pt-9 pb-9">
      <div class="container-semilla">
        <div class="col-12 pr-44 mb-0">
          <h2>Personalidad de marca</h2>
        </div>

        <div class="col-12">
          <div class="title-left-text-right mt-4">
            <div class="title">
              <p>Los principales territorios conceptuales de la ciudad se traducen en cuatro atributos que la marca debe reflejar en todas sus expresiones.</p>
            </div>

            <div class="text justify-start pl-3">
              <h3>Adaptable</h3>

              <p class="pb-3">Medellín es transformación y resiliencia, se acomoda al cambio y la diversidad de personas que la visitan <br> y la habitan</p>

              <h3>Alegre</h3>

              <p class="pb-3">Medellín es cálida como su gente; empática, amable <br> y cercana</p>

              <h3>Verde</h3>

              <p class="pb-3">Medellín está llena de flora y fauna sorprendente, <br> es biodiversa y sostenible</p>

              <h3>Creativa</h3>

              <p class="pb-3">Medellín marca tendencias, es innovadora y visionaria</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-width background-home pt-9 pb-9">
      <div class="container-semilla">
        <div class="col-12 pb-3">
          <h2>
            <span>Tono de voz</span>
          </h2>
        </div>

        <div class="col-12 pr-47 mb-0">
          <p>Medellín habla como un <span class="font-italic">flaneur</span> que disfruta de los detalles, que conversa con todos y se deja llevar por la aventura. Comparte recorridos sin rumbo que llegan al asombro como único destino. Resalta historias particulares que despiertan curiosidad <br> por lo general.</p>
        </div>
      </div>
    </div>

    <div class="full-width background-tierra-fertil pt-9 pb-5">
      <div class="container-semilla">
        <div class="col-12 pb-3">
          <h2 class="font-color-semilla">
            <span class="font-color-guarapo">Idea de marca</span>
          </h2>
        </div>

        <div class="col-12 pr-47 font-color-guarapo mb-0 pb-3">
          La idea de marca es la expresión creativa de la estrategia; sintetiza el posicionamiento de la ciudad en una frase corta y memorable. Reúne los dos territorios de Medellín como cuna de innovación y como experiencia de biodiversidad y naturaleza.
        </div>

        <div class="col-12">
          <h1 class="font-color-semilla"
              style="font-size: 10rem">
            <span class="font-color-semilla font-extra-bold">Aquí todo florece</span>
          </h1>
        </div>

        <div class="col-12 mt-6 justify-center align-center font-color-semilla"
             @click="$router.push('/crece')">
          <div class="button-next-page">
            Siguiente
            <img class="ml-1"
                 src="../assets/arrow_forward_guayaba.svg"
                 alt="Siguiente">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CirculoDorado from '../animations/CirculoDorado'

export default {
  components: { CirculoDorado },
  data: () => ({
    startTransition: false
  }),
  mounted () {
    this.startTransition = true
  }
}
</script>

<style>
.semilla-color {
  color: var(--florece);
}
</style>